import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '~/tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

const screensSizes = fullConfig?.theme?.screens as Record<string, string> | undefined;

export const screens = (size: 'sm' | 'md' | 'lg' | 'xl', removeUnit = true) => {
    const screen = `${screensSizes?.[size]}`;
    return removeUnit ? screen.replace('px', '') : screen;
};

// @ts-ignore
export const woomColors = fullConfig?.theme?.colors?.woom;
